if (document.getElementById("changeReleaseGrid")) {
  var vm = new Vue({
    data: () => ({
      nodeId: document.getElementById("nodeId").value,
      pagination: {
        currentPage: 1,
      },
      total: 100,
      maxSize: 3,
      itemsPerPage: 10,
      baseUrl:
        window.location.protocol +
        "//" +
        window.location.host +
        "/change/uk-link-releases",
      release: "All",
      releaseArr: ["All", "Major", "Minor"],
      changeReleaseData: [],
    }),
    methods: {
      resetFilter() {
        this.release = "All";
        this.getData(1);
      },
      setLink() {
        let newurl =
          window.location.protocol +
          "//" +
          window.location.host +
          "/change/uk-link-releases?release=" +
          this.release +
          "&pageNumber=" +
          this.pagination.currentPage;
        window.history.pushState({ path: newurl }, document.title, newurl);
      },
      setPage: function (pageNo) {
        this.pagination.currentPage = pageNo;
      },
      getQueryVariable(variable) {
        var query = window.location.search.substring(1);
        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
          var pair = vars[i].split("=");
          if (pair[0] == variable) {
            return pair[1];
          }
        }
        return "";
      },
      getData(paginationPosition) {
        document.querySelector("html").classList.add("loading");
        if (paginationPosition) {
          paginationPosition = 1;
        } else {
          paginationPosition = this.pagination.currentPage;
        }
        const self = this;
        const params = new URLSearchParams();
        params.append("nodeId", self.nodeId);
        params.append("pageNumber", paginationPosition);
        params.append("release", self.release != "All" ? self.release : "");
        axios
          .post("/umbraco/api/ChangeProposalApi/LinkUKeleasesFilter", params, {
            headers: {
              "Content-Type":
                "application/x-www-form-urlencoded; charset=utf-8",
            },
          })
          .then(function (response) {
            self.changeReleaseData = response.data;
          })
          .then(function () {
            self.total = self.changeReleaseData.TotalNumberOfRecords;
            self.itemsPerPage = self.changeReleaseData.PageSize;
            self.pagination.currentPage = self.changeReleaseData.PageNumber;
          })
          .then(function () {
            $("html, body").animate(
              {
                scrollTop: $(".main").offset().top,
              },
              300,
              function () {
                self.setLink();
                document.querySelector("html").classList.remove("loading");
              }
            );
          })
          .catch(function (error) {
            console.log(error);
            document.querySelector("html").classList.remove("loading");
          });
      },
    },
    created() {
      if (decodeURI(this.getQueryVariable("release"))) {
        this.release = decodeURI(this.getQueryVariable("release"));
      }
      if (decodeURI(this.getQueryVariable("pageNumber"))) {
        this.pagination.currentPage = parseInt(
          decodeURI(this.getQueryVariable("pageNumber"))
        );
      }
      this.getData();
      return false;
    },
  });
  if (Vue.options.components["uib-pagination"]) {
    vm.$mount("#changeReleaseGrid");
  } else {
    $.getScript(
      "/assets/js/app/vendor/vuejs-uib-pagination.min.js",
      function () {
        vm.$mount("#changeReleaseGrid");
      }
    );
  }
}
